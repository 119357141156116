<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; --color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <ion-buttons slot="start">
                    <ion-button
                        style="--padding-end: 4px; --padding-start: 4px"
                        @click="$router.go(-1)"
                    >
                        <i slot="start" class="mdi mdi-chevron-left color-fff"></i>
                    </ion-button>
                </ion-buttons>
                <ion-title>BC LÁI XE SỬ DỤNG LỆNH</ion-title>
                <ion-buttons slot="end">
                    <ion-button
                        style="--padding-end: 4px; --padding-start: 4px"
                        @click="
                            ifDialogLoc = true;
                            dialogLoc = true;
                        "
                    >
                        <i slot="start" class="mdi mdi-filter-variant color-fff"></i>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>

            <ion-toolbar style="--background: #white; --min-height: 48px">
                <slot name="center">
                    <div class="row align-center px-3">
                        <div style="width: calc(100% - 36px)">
                            <div class="row">
                                <div class="xs6 mr-2">
                                    <DxValidationGroup ref="formValidation1">
                                        <DxDateBox
                                            label="Từ ngày"
                                            labelMode="floating"
                                            v-model="NgayBatDau"
                                            displayFormat="dd/MM/yyyy"
                                            :use-mask-behavior="true"
                                            validationMessageMode="always"
                                            :showDropDownButton="false"
                                            styling-mode="underlined"
                                            :height="36"
                                        >
                                        </DxDateBox>
                                    </DxValidationGroup>
                                </div>
                                <div class="xs6 ml-2">
                                    <DxValidationGroup ref="formValidation2">
                                        <DxDateBox
                                            label="Đến ngày"
                                            labelMode="floating"
                                            v-model="NgayKetThuc"
                                            displayFormat="dd/MM/yyyy"
                                            :use-mask-behavior="true"
                                            validationMessageMode="always"
                                            :showDropDownButton="false"
                                            styling-mode="underlined"
                                            :height="36"
                                        >
                                        </DxDateBox>
                                    </DxValidationGroup>
                                </div>
                            </div>
                        </div>
                        <div class="ml-3" style="width: 36px">
                            <ion-button
                                size="small"
                                style="--padding-end: 4px; --padding-start: 4px"
                                @click="LayDanhSachLenh"
                            >
                                <i slot="start" class="mdi mdi-magnify"></i>
                            </ion-button>
                        </div>
                    </div>
                </slot>
            </ion-toolbar>

            <ion-toolbar style="--background: #white; --min-height: 48px">
                <slot name="start">
                    <SapXepVue ref="SapXepVue" />
                </slot>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <div class="row mt-2">
                <DxList
                    :data-source="{
                        store: DanhSachLenh,
                        paginate: true,
                        requireTotalCount: true,
                        pageSize: 10,
                        sort:
                            this.$refs.SapXepVue && this.$refs.SapXepVue.isSapXep
                                ? [
                                      {
                                          selector: this.$refs.SapXepVue
                                              ? this.$refs.SapXepVue.tieuChiSapXep
                                              : '',
                                          desc: this.$refs.SapXepVue
                                              ? this.$refs.SapXepVue.kieuSapXepDesc
                                              : false,
                                      },
                                  ]
                                : null,
                        filter: dataFilter,
                    }"
                    height="calc(100% - 8px)"
                    page-load-mode="nextButton"
                    key-expr="guidLenh"
                    :hoverStateEnabled="false"
                    :focusStateEnabled="false"
                    :activeStateEnabled="false"
                    no-data-text="Không có dữ liệu!"
                    class="list-lenh-style"
                >
                    <template #item="{ data: item }">
                        <FrameItemVue
                            :item="item"
                            @XemBanTheHienLenh="XemBanTheHienLenh"
                            @LayDanhSachLenh="LayDanhSachLenh"
                        />
                    </template>
                </DxList>
            </div>
            <div
                v-if="tempError"
                style="
                    text-align: left;
                    padding: 8px 16px 7px;
                    color: rgba(0, 0, 0, 0.54);
                    font-size: 12px;
                "
            >
                Không có dữ liệu!
            </div>
        </ion-content>

        <!-- ====================================Popup Lọc ========================================= -->
        <PopupVue
            height="auto"
            v-if="ifDialogLoc"
            v-model:dialog="dialogLoc"
            title="Lọc dữ liệu"
            buttonTextRight="Xác nhận"
            buttonTextLeft="Hủy"
            @close="closeLoc"
            classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
            :key="`${componentKey}-3`"
        >
            <template #content>
                <PopupLocDuLieuVue ref="LocDuLieu" :filter="filter" />
            </template>
        </PopupVue>

        <!-- ====================================Bản thể hiện lệnh ========================================= -->
        <PopupBanTheHienLenhVue
            :dialog="dialogBanTheHienLenh"
            :guidLenh="LenhDangChon.guidLenh"
            :laLenhGiay="LenhDangChon.laLenhGiay"
            :danhSachLenhGiay="LenhDangChon.danhSachBanTheHien || []"
            @closes="
                () => {
                    dialogBanTheHienLenh = false;
                }
            "
        />
    </ion-page>
</template>
<script>
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxList, DxTabs, DxButton } from "devextreme-vue";
import { DxDateBox } from "devextreme-vue/date-box";
import {
    IonPage,
    onIonViewWillEnter,
    onIonViewWillLeave,
    IonContent,
    IonButton,
} from "@ionic/vue";
import PopupLocDuLieuVue from "./components/PopupLocDuLieu";
import PopupBanTheHienLenhVue from "../components/PopupBanTheHienLenh";
import PopupVue from "../../../../src/components/_Common/Popup";
import FrameItemVue from "./components/FrameItem";
import SapXepVue from "./components/SapXep.vue";
export default {
    components: {
        DxValidationGroup,
        DxList,
        DxTabs,
        DxButton,
        DxDateBox,
        IonPage,
        IonContent,
        IonButton,
        PopupLocDuLieuVue,
        PopupBanTheHienLenhVue,
        PopupVue,
        FrameItemVue,
        SapXepVue,
    },
    props: {},
    data() {
        return {
            NgayBatDau: this.$Helper.getStartDay(new Date().toISOString()),
            NgayKetThuc: this.$Helper.getEndDay(new Date().toISOString()),
            LenhDangChon: {},
            ifDialogLoc: false,
            dialogLoc: false,
            dialogBanTheHienLenh: false,
            DanhSachLenh: [],
            filter: {},
        };
    },
    computed: {
        dataFilter() {
            let trangThai =
                this.filter.TrangThai && this.filter.TrangThai != 0
                    ? ["idTrangThai", "=", parseInt(this.filter.TrangThai)]
                    : null;
            let timKiem = this.filter.TimKiem
                ? [
                      ["benDi", "contains", this.filter.TimKiem],
                      "or",
                      ["benDen", "contains", this.filter.TimKiem],
                      "or",
                      ["bienKiemSoat", "contains", this.filter.TimKiem],
                  ]
                : null;
            let filter =
                trangThai && timKiem
                    ? [trangThai, "and", timKiem]
                    : trangThai
                    ? trangThai
                    : timKiem
                    ? timKiem
                    : null;
            return filter;
        },
    },
    watch: {},
    methods: {
        async LayDanhSachLenh() {
            try {
                let query = {
                    trangThai: 0,
                    timKiem: null,
                    idLuongTuyen: null,
                    ngayXuatBenKeHoach: null,
                    tuNgay: this.$Helper.getStartDay(this.NgayBatDau),
                    denNgay: this.$Helper.getEndDay(this.NgayKetThuc),
                    danhSachGioXuatBen: [],
                };
                this.DanhSachLenh = (
                    await this.$store.dispatch("Lenh/LayBaoCaoSuDungLenhLaiXe", query)
                ).Store;
            } catch (ex) {
                console.log("🚀 ~ ex:", ex);
                this.$Helper.ThongBaoToast("error", "Lỗi hệ thống!");
            }
        },
        XemBanTheHienLenh(item) {
            this.LenhDangChon = item;
            this.dialogBanTheHienLenh = true;
        },

        closeLoc(param) {
            if (!param) {
                this.dialogLoc = false;
                return;
            }
            try {
                //Lấy thông tin lọc dữ liệu
                let valid = this.$refs.LocDuLieu.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return;
                }
                let trangThai = this.$refs.LocDuLieu.model.TrangThai;
                let timKiem = this.$refs.LocDuLieu.model.TimKiem;
                //Gán dữ liệu lọc
                this.filter.TrangThai = trangThai;
                this.filter.TimKiem = timKiem;
                this.dialogLoc = false;
                //Không lọc theo đang thực hiện
                this.LayDanhSachLenh();
            } catch (error) {
                this.thongBaoLoi(error);
            }
        },
    },
    created() {
        let self = this;
        onIonViewWillEnter(() => {
            self.ifDialogLoc = false;
            self.NgayBatDau = this.$Helper.getStartDay(new Date().toISOString());
            self.NgayKetThuc = this.$Helper.getEndDay(new Date().toISOString());
        });
    },
    mounted() {},
};
</script>
<style scoped>
>>> .list-lenh-style .dx-item.dx-list-item {
    padding: 4px 16px 12px;
}
>>> .list-lenh-style .dx-template-wrapper.dx-item-content.dx-list-item-content {
    padding: 0px;
}
</style>
<style lang="scss" scoped></style>
