<template>
    <div>
        <DxValidationGroup ref="formValidation">
            <div class="pb-3">
                <DxSelectBox
                    label="Trạng thái (*)"
                    labelMode="floating"
                    v-model="model.TrangThai"
                    :data-source="DanhSachTrangThai"
                    value-expr="Id"
                    display-expr="text"
                    styling-mode="underlined"
                    validationMessageMode="always"
                    :onValueChanged="onChangeTrangThai"
                >
                    <DxValidator>
                        <DxRequiredRule message="Trạng thái không được bỏ trống!" />
                    </DxValidator>
                </DxSelectBox>
            </div>
            <div class="pb-3">
                <DxTextBox
                    label="Tìm kiếm (Bến xe; Tên tuyến; BKS)"
                    labelMode="floating"
                    v-model="model.TimKiem"
                    validationMessageMode="always"
                    mode="text"
                    styling-mode="underlined"
                >
                    <DxValidator>
                        <DxStringLengthRule
                            :max="128"
                            message="Tìm kiếm không được vượt quá 128 ký tự!"
                        />
                    </DxValidator>
                </DxTextBox>
            </div>
        </DxValidationGroup>
    </div>
</template>
<script>
import {
    DxDateBox,
    DxSelectBox,
    DxValidationGroup,
    DxValidator,
    DxButton,
    DxTextBox,
} from "devextreme-vue";
import { DxDropDownBox } from "devextreme-vue/drop-down-box";
import { DxList } from "devextreme-vue/list";
import { DxRequiredRule, DxStringLengthRule } from "devextreme-vue/data-grid";

export default {
    layout: "application",
    components: {
        DxButton,
        DxValidationGroup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxSelectBox,
        DxDropDownBox,
        DxList,
        DxTextBox,
        DxStringLengthRule,
    },
    props: {
        filter: {
            type: Object,
            default: () => ({
                TrangThai: 0,
                TimKiem: null,
            }),
        },
    },
    data() {
        return {
            model: {
                TimKiem: null,
                TrangThai: 0,
            },
            data: {
                TuyenVanChuyen: [],
                GioXuatBenKH: [],
            },
            DanhSachTrangThai: [
                {
                    Id: 0,
                    text: "Tất cả",
                },
                {
                    Id: this.$t("TrangThaiLenh.ChoKichHoat"),
                    text: "Chờ kích hoạt",
                },
                {
                    Id: this.$t("TrangThaiLenh.ChoBenDiKy"),
                    text: "Chờ bến đi ký",
                },
                {
                    Id: this.$t("TrangThaiLenh.BenDiDaKy"),
                    text: "Bến đi đã ký",
                },
                {
                    Id: this.$t("TrangThaiLenh.DangThucHienHanhTrinh"),
                    text: "Đang thực hiện hành trình",
                },
                {
                    Id: this.$t("TrangThaiLenh.ChoXacNhanTraKhach"),
                    text: "Chờ xác nhận trả khách",
                },
                {
                    Id: this.$t("TrangThaiLenh.HoanThanh"),
                    text: "Hoàn thành",
                },
                {
                    Id: this.$t("TrangThaiLenh.KhongThucHienHanhTrinh"),
                    text: "Không thực hiện hành trình",
                },
                {
                    Id: this.$t("TrangThaiLenh.KhongHoanThanhHanhTrinh"),
                    text: "Không hoàn thành hành trình",
                },
                {
                    Id: this.$t("TrangThaiLenh.KhongDuDieuKienXuatBen"),
                    text: "Không đủ điều kiện xuất bến",
                },
                {
                    Id: this.$t("TrangThaiLenh.Huy"),
                    text: "Hủy",
                },
            ],
            CheckLayDsTuyen: false,
        };
    },
    computed: {
        GioXuatBenKH_selected: {
            get() {
                // return this.data.GioXuatBenKH.filter(x =>);
            },
        },
    },
    methods: {
        selectedItemKeys(e) {},
        onContentReady(e) {
            var html = e.component.content();
            html.style.cssText = "padding: 8px !important";
        },
    },
    created() {},
    mounted() {
        setTimeout(() => {
            if (this.filter.NgayXuatBenKH) {
                this.model.TrangThai = this.filter.TrangThai;
                this.model.TimKiem = this.filter.TimKiem;
            }
        }, 100);
    },
};
</script>
<style scoped></style>
<style lang="scss" scoped></style>
